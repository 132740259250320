import { tns } from "tiny-slider/src/tiny-slider";
import LazyLoad from "vanilla-lazyload";
import init from "./common";

console.log("test");
console.log("test2");
init();
// Init Lazy load instance
var lazyLoad = new LazyLoad();
function sliderLazy() {
  lazyLoad.update();
}

// var sliderAccueil = tns({
//   container: ".slider-accueil",
//   items: 1,
//   loop: true,
//   autoplay: true,
//   autoplayButtonOutput: false,
//   controls: true,
//   nav: false,
//   gutter: 0,
//   controlsContainer: ".controls-slider-accueil",
// });
// sliderAccueil.events.on("transitionEnd", sliderLazy);

var sliderActualites = tns({
  container: ".slider-actualites",
  items: 1,
  center: false,
  loop: true,
  fixedWidth: 400,
  autoplay: true,
  autoplayButtonOutput: false,
  controls: true,
  nav: false,
  gutter: 60,
  controlsContainer: ".controls-slider-actualites",
});

sliderActualites.events.on("transitionEnd", sliderLazy);

console.log("home_js");
console.log("test");
