import "jquery.marquee";

function eventFire(el, etype) {
  if (el.fireEvent) {
    el.fireEvent("on" + etype);
  } else {
    var evObj = document.createEvent("Events");
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

export default function init() {
  // access button link Orange confort
  jQuery(".header__flash-infos .ticker").marquee({
    // duration: 10000,
    speed: 80,
    gap: 30,
    delayBeforeStart: 0,
    direction: "left",
    duplicated: true,
    pauseOnHover: true,
    startVisible: false,
  });

  jQuery("#access-link").on("click", function () {
    eventFire(document.getElementById("uci_link"), "click");
  });

  const buttonDemarches = jQuery(".button-demarches");
  buttonDemarches.on("click", function () {
    console.log("click");
    jQuery(".demarches-container").toggleClass("active");
  });
  const menuItem = jQuery(".menu-item");
  menuItem.on("click", function () {
    jQuery(".js-submenu.active").removeClass("active");

    jQuery(".bandeau-int").css("height", "");

    jQuery(".menu-item").addClass("opacity-50");
    jQuery(this).removeClass("opacity-50");
    const menuItem_id = jQuery(this).data("menu");
    if (jQuery("#" + menuItem_id).hasClass("visible")) {
      jQuery("#" + menuItem_id).removeClass("visible");
      jQuery(".menu-item").removeClass("opacity-50");
    } else {
      jQuery(".children-menu").removeClass("visible");
      jQuery("#" + menuItem_id).addClass("visible");
    }
    if (jQuery(".children-menu").hasClass("visible")) {
      jQuery(".menu-overlay").addClass("visible");
      jQuery(".bandeau-int").addClass("big");
      jQuery(".logo-bandeau").addClass("hidden");
    } else {
      jQuery(".menu-overlay").removeClass("visible");
      jQuery(".bandeau-int").removeClass("big");
      jQuery(".logo-bandeau").removeClass("hidden");
    }
  });

  jQuery(".menu-open").on("click", function () {
    jQuery(".menu").toggleClass("active");
  });

  jQuery(".js-open-submenu").on("click", function () {
    var id = jQuery(this).data("id");
    var submenu = jQuery(".js-submenu[data-id='" + id + "']");

    if (submenu.hasClass("active")) {
      submenu.removeClass("active");
      jQuery(".bandeau-int").css("height", "");
      return;
    }

    // close all .js-submenu.active
    jQuery(".js-submenu.active").removeClass("active");
    // if
    jQuery(".bandeau-int").css("height", "");

    // open .js-submenu with same data-id
    submenu.toggleClass("active");
    // get height of .js-submenu
    var height = submenu.height();
    // get height of .bandeau-int
    var heightBandeau = jQuery(".bandeau-int").height();

    if (submenu.hasClass("active")) {
      jQuery(".bandeau-int").css("height", height + heightBandeau);
    } else {
      // remove height property
      jQuery(".bandeau-int").css("height", "");
    }
  });

  //Clicking outside .header will close the menu
  jQuery(document).on("click", function (event) {
    if (!jQuery(event.target).closest(".header").length) {
      console.log("click outside");
      jQuery(".js-submenu.active").removeClass("active");
      jQuery(".bandeau-int").css("height", "");
      jQuery(".menu-item").removeClass("opacity-50");
      jQuery(".menu-overlay").removeClass("visible");
      jQuery(".bandeau-int").removeClass("big");
      jQuery(".logo-bandeau").removeClass("hidden");
      jQuery(".children-menu").removeClass("visible");
    }
  });

  // Listes Depliables
  jQuery(".liste-depliable__title").on("click", function () {
    jQuery(this).toggleClass("active");
    jQuery(this).next(".liste-depliable__content").slideToggle();
  });

  jQuery(document).on("click", 'a[href^="#"]', function (event) {
    event.preventDefault();
    console.log("click");
    jQuery("html, body").animate(
      {
        scrollTop: jQuery(jQuery.attr(this, "href")).offset().top,
      },
      600
    );
  });

  jQuery(document).scroll(function () {
    var y = jQuery(this).scrollTop();
    if (y > 800) {
      jQuery(".search-toogle").removeClass("hidden");
    } else {
      jQuery(".search-toogle").addClass("hidden");
    }
  });

  // Gtranslate

  function cleartimer() {
    setTimeout(function () {
      window.clearInterval(myVar);
    }, 1000);
  }
  function myTimer() {
    if (jQuery(".goog-te-combo option:first").length) {
      jQuery(".goog-te-combo option:contains('Sélectionner une langue')").html(
        "FR"
      );
      jQuery(".goog-te-combo option:contains('Anglais')").html("EN");
      jQuery(".goog-te-combo option:contains('Allemand')").html("DE");
      jQuery(".goog-te-combo option:contains('Espagnol')").html("ES");
      cleartimer();
    }
    jQuery(".goog-te-combo").on("change", function () {
      console.log("test");
      jQuery(".goog-te-combo option:contains('Sélectionner une langue')").html(
        "FR"
      );
      jQuery(".goog-te-combo option:contains('Anglais')").html("EN");
      jQuery(".goog-te-combo option:contains('Allemand')").html("DE");
      jQuery(".goog-te-combo option:contains('Espagnol')").html("ES");
      cleartimer();
    });
  }
  var myVar = setInterval(function () {
    myTimer();
  }, 300);

  jQuery("#js-menu-open, #js-menu-close, #js-menu-overlay").on(
    "click",
    function () {
      jQuery("#js-menu-overlay").toggleClass("invisible");
      jQuery("#js-menu-overlay").toggleClass("opacity-50");
      jQuery("#js-menu-overlay").toggleClass("opacity-0");
      jQuery("#js-menu").toggleClass("-left-full");
      jQuery("#js-menu").toggleClass("left-0");
    }
  );

  jQuery(".search-button").on("click", function () {
    jQuery("#searchform_site").toggleClass("hidden");
    jQuery("#search-input").focus();
    jQuery("#search-input").val("");
  });

  (function () {
    const links = document.querySelectorAll(
      "a[href^='https://'], a[href^='http://']"
    );
    const host = window.location.hostname;

    const isInternalLink = (link) => new URL(link).hostname === host;

    links.forEach((link) => {
      if (isInternalLink(link)) return;

      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener");
    });
  })();
}
